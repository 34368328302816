<template>
  <div class="backgroundLoin">
    <div class="tit">
      <div class="tit1">HI，你好</div>
      <div class="tit2">欢迎进入实验考试系统市级平台</div>
      <div class="tit3">
        Welcome to the experimental examination system municipal platform
      </div>
    </div>
    <el-card class="box-card">
      <div class="box-card-tit">平台登录</div>
      <el-form ref="formLion" :rules="rules" :model="formLion">
        <el-form-item label="注册方式" v-if="0">
          <el-radio-group v-model="formLion.loginType">
            <el-radio :disabled="disabled" :label="1">账号</el-radio>
            <el-radio :disabled="disabled" :label="2">手机号</el-radio>
            <el-radio :disabled="disabled" :label="3">邮箱</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="account" style="margin-bottom: 36px">
          <el-input
            prefix-icon="el-icon-user-solid"
            v-model="formLion.account"
            :placeholder="accountText"
            :disabled="disabled"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password" style="margin-bottom: 36px">
          <el-input
            v-model="formLion.password"
            placeholder="密码"
            show-password
            prefix-icon="el-icon-lock"
            :disabled="disabled"
          ></el-input>
        </el-form-item>
        <el-form-item style="margin-bottom: 16px">
          <el-button
            v-throttle
            type="primary"
            :loading="loadingBtn"
            @click="onLogin('formLion')"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
    <div class="bottom_tit">{{ versionNum }}</div>
  </div>
</template>

<script>
import { login, getVersion } from '@/api/auth.js'
import {
  setToken,
  setName,
  setLoginRoad,
  setStore,
  getStore,
  removeStore,
} from '@/utils/token.js'
export default {
  name: 'cityPlatformLogin',
  data() {
    return {
      //版本号
      versionNum: 'msetp.school.v3.0.0',
      formLion: {
        account: '',
        // 登录类型,1为账号登录，2为手机号登录，3为邮箱登录
        loginType: 1,
        password: '',
        userType: 0,
        loginTerminalType: 11,
      },
      accountText: '请输入用户名',
      loadingBtn: false,
      disabled: false,
      // 验证
      rules: {
        account: [{ required: true, message: '账号不能为空', trigger: 'blur' }],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
        ],
      },
    }
  },
  mounted() {
    this.getVersionFunc()
    // 监听回车事件
    let _this = this
    document.onkeydown = function (e) {
      let ev = document.all ? window.event : e
      if (ev.keyCode === 13) {
        _this.onLogin('formLion')
      }
    }
  },
  methods: {
    onLogin(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.disabled = this.loadingBtn = true
          // 在此发送请求
          login(this.formLion)
            .then((res) => {
              this.disabled = this.loadingBtn = false
              console.log(res)
              if (res.success) {
                // 存储Token vuex 或者 浏览器上储存
                setToken(res.data.token)
                setStore('userId',res.data.userId)
                setName(this.formLion.account)
                // 存储入口
                setLoginRoad()
                this.$message.success('登录成功，正在转跳首页···')
                // 成功后跳转路由
                setTimeout(() => {
                  this.$router.replace('/homepage')
                }, 500)
              } else {
                this.$message.warning(res.msg)
              }
            })
            .catch((err) => {
              this.disabled = this.loadingBtn = false
              this.$message.warning('登录失败')
            })
        } else {
          return false
        }
      })
    },
    // 获取版本号
    getVersionFunc() {
      getVersion().then((res) => {
        console.log(res)
        if (res.success) {
          this.versionNum = res.data
        } else {
          this.versionNum = `版本获取失败，code:${res.code}`
        }
      })
    },
  },
}
</script>

<style scoped>
.backgroundLoin {
  width: 100vw;
  height: 100vh;
  position: relative;
  background: url(../../assets/login/cityPlatformLogin.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.tit {
  color: #fff;
  margin-top: -60px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
}
.tit1 {
  font-size: 72px;
  letter-spacing: 5px;
  font-weight: bold;
  margin-bottom: 60px;
}
.tit2 {
  font-size: 62px;
  letter-spacing: 5px;
  font-weight: bold;
  margin-bottom: 30px;
}
.tit3 {
  letter-spacing: 1px;
  font-size: 22px;
}
.box-card {
  width: 400px;
  padding: 20px;
  box-shadow: 5px 5px 15px 1px rgba(209, 209, 209, 0.38);
}
.box-card-tit {
  text-align: center;
  color: #2873e0;
  font-size: 30px;
  margin-bottom: 30px;
  font-weight: bold;
  letter-spacing: 6px;
}
::v-deep .el-input__prefix {
  color: #2873e0;
}
.el-button--primary {
  width: 100%;
  background: #1164dd;
}
.bottom_tit {
  position: absolute;
  bottom: 10px;
  color: #ffffff;
  font-size: 18px;
}
</style>
